import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {register} from '../utils/api-lists/login.api';
import {useRole} from '../context/RoleContext';

// Components
import {
    Form,
    LeftContainer,
    RightContainer,
    SideWrapper,
    TextContainer
} from '../components/SideWrapper/SideWrapper.styles';
import LogoAndText from '../components/LogoAndText';
import AddClientImage from '../components/AddClientImage';
import SignOptions from '../components/SignOptions';
import {useTranslation} from "react-i18next";

const Signup2Page = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const {role} = useParams();
    const decodedRole = decodeURIComponent(role);
    const {roleCheck} = useRole();
    const {t} = useTranslation('common');

    useEffect(() => {
        if (!roleCheck) {
            navigate('/sign-up'); // Redirect to the first page if the role is not set
        }
    }, [roleCheck, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await register(firstName, lastName, email, password, decodedRole);
            setSuccess(true);
            setError(null);
            navigate('/almost-there');
        } catch (err) {
            setError(err);
            setSuccess(false);
        }
    };

    return (
        <SideWrapper>
            <LeftContainer>
                <LogoAndText/>
            </LeftContainer>
            <RightContainer>
                <h2>{t('sign_up')}</h2>
                <TextContainer style={{marginBottom: '40px'}}>
                    <h3>{t('create_an_account')}</h3>
                </TextContainer>
                <AddClientImage showText={false}/>
                <Form onSubmit={handleSubmit}>
                    <label>{t('name')}</label>
                    <input placeholder={t('name')} name='name' type='text'
                           onChange={(e) => setFirstName(e.target.value)}
                           required/>
                    <label>{t('surname')}</label>
                    <input placeholder={t('surname')} name='surname' type='text'
                           onChange={(e) => setLastName(e.target.value)} required/>
                    <label>{t('email')}</label>
                    <input placeholder={t('email')} name='email' type='email' onChange={(e) => setEmail(e.target.value)}
                           required/>
                    <label>{t('password')}</label>
                    <input placeholder={t('password')} name='password' type='password'
                           onChange={(e) => setPassword(e.target.value)} required/>
                    <p>{t('terms_and_conditions')}</p>
                    <button type="submit" className="btn btn-4">{t('next')}</button>
                    {error && <p style={{color: 'red'}}>{t('error_message')}</p>}
                    {success && <p style={{color: 'green'}}>{t('success_message')}</p>}
                </Form>
                <SignOptions signIn={false}/>
                <p>{t('have_an_account')} <span onClick={() => navigate('/login')}>{t('sign_in')}</span></p>
            </RightContainer>
        </SideWrapper>
    );

}

export default Signup2Page;
