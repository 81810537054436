import React, {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../context/UserContext';
import {useTranslation} from 'react-i18next';

// Components
import {
    Form,
    LeftContainer,
    RightContainer,
    SideWrapper,
    TextContainer
} from '../components/SideWrapper/SideWrapper.styles';
import LogoAndText from '../components/LogoAndText';
import SignOptions from '../components/SignOptions';
import {login} from "../utils/api-lists/login.api";


const LoginPage = () => {
    const navigate = useNavigate();
    const {t} = useTranslation('common');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const {loginUser} = useContext(UserContext);

    const handleSubmit = async (e) => {
        console.log('Submit');
        e.preventDefault();
        try {
            const user = await login(email, password);
            loginUser(user);
            navigate('/dashboard');
        } catch (err) {
            setError(err);
        }
    };

    return (
        <SideWrapper>
            <LeftContainer>
                <LogoAndText/>
            </LeftContainer>
            <RightContainer>
                <h2>{t('sign_in')}</h2>
                <TextContainer>
                    <h3>{t('please_sign_in')}</h3>
                </TextContainer>
                <Form onSubmit={handleSubmit} style={{marginTop: "121px", maxWidth: "400px"}}>
                    <label>{t('email')}</label>
                    <input placeholder={t('email')} name='email' type='email'
                           onChange={(e) => setEmail(e.target.value)}/>
                    <label>{t('password')}</label>
                    <input placeholder={t('password')} name='password' type='password'
                           onChange={(e) => setPassword(e.target.value)}/>
                    {error && <p style={{color: 'red'}}>{error}</p>}
                    <button type="submit" className="btn btn-4">{t('next')}</button>
                </Form>
                <SignOptions signIn={true}/>
                <p>{t('dont_have_account')} <span onClick={() => navigate('/sign-up')}>{t('sign_up')}</span></p>
                <p>{t('forget_your_password')} <span
                    onClick={() => navigate('/forgot-password')}>{t('forgot_password')}</span></p>
            </RightContainer>
        </SideWrapper>
    );

}

export default LoginPage;
