import React, {useContext, useState} from "react";
import {SearchBar, SearchResult, SearchResults, Wrapper} from "./Search.styles";
import {searchPatients} from "../../utils/api-lists/patient.api";
import {UserContext} from "../../context/UserContext";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Search = () => {
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const {user} = useContext(UserContext);

    const {t} = useTranslation('common');
    const navigate = useNavigate();

    const fetchPatients = (value) => {
        if (value === '') {
            setSearchResults([]);
            return;
        }
        searchPatients(user.id, value).then((response) => {
            console.log(response);
            setSearchResults(response);
        });
    };

    const handleSearch = (value) => {
        setSearch(value);
        fetchPatients(value);
    }

    return (
        <Wrapper>
            <SearchBar type="text" id="search" name="search" placeholder={t('search')} value={search}
                       onChange={(e) => handleSearch(e.target.value)}/>
            {searchResults.length > 0 ? <SearchResults>
                {
                    searchResults.map((patient, id) => {
                        return <SearchResult onClick={() => {
                            navigate('/patient-cabinet/' + patient.id);
                        }} key={id}>
                            {patient.first_name} {patient.last_name}
                        </SearchResult>
                    })
                }
            </SearchResults> : null}
        </Wrapper>
    )
};

export default Search;
