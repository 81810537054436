import React from "react";
import {useNavigate} from 'react-router-dom';
import {Wrapper} from "./LogoAndText.styles";
import Logo from "../../assets/logo.svg";
import {useTranslation} from "react-i18next";

const LogoAndText = () => {
    const navigate = useNavigate();
    const {t} = useTranslation('common');

    return (
        <Wrapper>
            <img onClick={() => navigate('/')} src={Logo} alt="logo"/>
            <p>{t('intro (2)')}</p>
        </Wrapper>
    )
};

export default LogoAndText;
