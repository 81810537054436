import React, {useCallback, useEffect, useRef, useState} from "react";
import {CamControls, Wrapper} from "./Camera.styles";
import CircleButton from "../../widgets/AddIcon";
import {FaCamera, FaCheck, FaRegDotCircle, FaRegStopCircle} from "react-icons/fa";
import {FaCameraRotate, FaTrashCan} from "react-icons/fa6";
import FaceDetection from "../FaceDetection/FaceDetection";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const Camera = (props) => {

    const webcamRef = useRef(null); // create a webcam reference
    const mediaRecorderRef = React.useRef(null);
    const [recordedChunks, setRecordedChunks] = React.useState([]);
    const [imgSrc, setImgSrc] = useState(null);
    const [mirrored, setMirrored] = useState(false);
    const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
    const [isVideo, setIsVideo] = useState(props.isVideo);
    const [capturing, setCapturing] = React.useState(false);

    const changeCamera = useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
        );
    }, []);

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
    }, [webcamRef]);

    const retake = () => {
        setImgSrc(null);
    };

    const handleStartCaptureClick = React.useCallback(() => {
        if (webcamRef.current && webcamRef.current.stream) {
            setCapturing(true);
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
                mimeType: "video/webm"
            });
            mediaRecorderRef.current.addEventListener(
                "dataavailable",
                handleDataAvailable
            );
            mediaRecorderRef.current.start();
        } else {
            console.error("Webcam stream is not available.");
        }
    }, [webcamRef, setCapturing, mediaRecorderRef]);

    const handleDataAvailable = React.useCallback(
        ({data}) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    useEffect(() => {
        if (recordedChunks.length) {
            saveVideo();
        }
    }, [recordedChunks]);

    const handleStopCaptureClick = React.useCallback(() => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setCapturing(false);
        }
    }, [mediaRecorderRef, webcamRef, setCapturing]);

    function randomString(length) {
        return Math.random().toString(36).substring(2, 2 + length);
    }

    const saveVideo = React.useCallback(() => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/webm"
            });
            setRecordedChunks([]);
            var file = new File([blob], `${randomString(12)}.webm`, {lastModified: Date.now()});
            props.saveVideo(file);
        }
    }, [recordedChunks, props]);

    return (
        <Wrapper>
            {FaceDetection(webcamRef, mirrored, facingMode, !!imgSrc)}
            {imgSrc && <img src={imgSrc} alt="webcam"/>}
            {/*{imgSrc ? (*/}
            {/*    <img src={imgSrc} alt="webcam"/>*/}
            {/*) : (*/}
            {/*    FaceDetection(webcamRef, mirrored, facingMode)*/}
            {/*    // <Webcam*/}
            {/*    //     audio={false}*/}
            {/*    //     className="webcam"*/}
            {/*    //     ref={webcamRef}*/}
            {/*    //     mirrored={mirrored}*/}
            {/*    //     videoConstraints={{*/}
            {/*    //         facingMode*/}
            {/*    //     }}*/}
            {/*    // />*/}
            {/*)}*/}
            <CamControls>
                {imgSrc ? (
                    <>
                        <CircleButton onClick={retake}>
                            <FaTrashCan/>
                        </CircleButton>

                        <CircleButton onClick={() => {
                            props.saveImage(imgSrc);
                        }}>
                            <FaCheck/>
                        </CircleButton>
                    </>
                ) : (
                    <>
                        <div>
                            <input
                                type="checkbox"
                                checked={mirrored}
                                onChange={(e) => setMirrored(e.target.checked)}
                            />
                            <label>Mirror</label>
                        </div>
                        {isVideo ? (
                            <>
                                {capturing ? (
                                    <CircleButton onClick={handleStopCaptureClick}>
                                        <FaRegStopCircle/>
                                    </CircleButton>
                                ) : (
                                    <CircleButton onClick={handleStartCaptureClick}>
                                        <FaRegDotCircle/>
                                    </CircleButton>
                                )}
                            </>
                        ) : (
                            <CircleButton onClick={capture}>
                                <FaCamera/>
                            </CircleButton>
                        )}
                        <CircleButton onClick={changeCamera}>
                            <FaCameraRotate/>
                        </CircleButton>
                    </ >
                )}
            </CamControls>
        </Wrapper>
    )
};

export default Camera;