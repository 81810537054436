import React, {useContext, useEffect, useState} from "react";
import {Section, Wrapper} from "./DoctorProfile.styles";
import {UserContext} from '../../context/UserContext';
import Input from "../Input";
import AddClientImage from "../AddClientImage";
import {getDoctor} from "../../utils/api-lists/doctor.api";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SelectField from "../../widgets/Select";

const DoctorProfile = () => {
    const userContext = useContext(UserContext);
    const [doctor, setDoctor] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation('common');
    const currentLanguage = localStorage.getItem('language') || 'en';
    const [language, setLanguage] = useState(currentLanguage);

    useEffect(() => {
        const user = userContext.user;
        if (user === null) return;

        getDoctor(user.id).then((data) => {
            setDoctor(data);
        }).catch((err) => {
            setError(err);
        });
    }, [userContext.user]);

    if (error) {
        return <p style={{color: 'red'}}>{t('error_message')}</p>;
    }

    if (!doctor) {
        return <p>{t('loading')}</p>;
    }

    const setLanguageUI = (language) => {
        i18n.changeLanguage(language);
        setLanguage(language);
        localStorage.setItem('language', language);
    }

    return (
        <Wrapper>
            <Section>
                <h4>{t('profile_photo')}</h4>
                <div>
                    <AddClientImage showText={false}/>
                </div>
            </Section>
            <Section>
                <h4>{t('organization')}</h4>
                <Input type="text" name="organization" placeholder={t('organization')} disabled={true}/>
            </Section>
            <Section>
                <h4>{t('name')}</h4>
                <Input type="text" name="name" value={doctor.first_name} placeholder={t('first_name')} disabled={true}/>
            </Section>
            <Section>
                <h4>{t('surname')}</h4>
                <Input type="text" name="last_name" value={doctor.last_name} placeholder={t('last_name')}
                       disabled={true}/>
            </Section>
            <Section>
                <h4>{t('email_address')}</h4>
                <Input type="email" name="email" value={doctor.email} placeholder={t('email')} disabled={true}/>
            </Section>
            <Section>
                <h4>{t('password')}</h4>
                <button onClick={() => {
                    navigate('/change-password');
                }} type="submit" className="btn btn-2 btn-align-right">{t('change_password')}</button>
            </Section>
            <Section>

                <h4>{t('language-selector.label')}</h4>
                <SelectField
                    name="translations"
                    id="translations"
                    defaultValue={currentLanguage}
                    onChange={(e) => setLanguageUI(e.target.value)}
                >
                    <option value="en">EN</option>
                    <option value="it">IT</option>
                </SelectField>
            </Section>
        </Wrapper>
    )
};

export default DoctorProfile;