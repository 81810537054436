import {Wrapper} from "./ChangePassword.styles";
import {Form, Header} from "../NewPatient/NewPatient.styles";
import Input from "../Input";
import React, {useContext} from "react";
import {changePassword} from "../../utils/api-lists/login.api";
import {UserContext} from "../../context/UserContext";
import {useTranslation} from "react-i18next";

const ChangePassword = () => {
    const userContext = useContext(UserContext);
    const [oldPassword, setOldPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [repeatPassword, setRepeatPassword] = React.useState('');
    const [error, setError] = React.useState(null);
    const [success, setSuccess] = React.useState(null);
    const {t} = useTranslation('common');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== repeatPassword) {
            setError(t('passwords_do_not_match'));
            return;
        }
        try {
            const email = userContext.user.email;
            const response = await changePassword(email, oldPassword, newPassword);
            setSuccess(t('password_changed_successfully'));
        } catch (err) {
            setError(err);
        }
    }

    return (
        <Wrapper>
            <Header>
                <p>{t('change_password')}</p>
            </Header>
            <Form onSubmit={handleSubmit}>
                <Input type="password" name="oldPassword" placeholder={t('old_password')} value={oldPassword}
                       onChange={(e) => setOldPassword(e.target.value)} required/>
                <Input type="password" name="newPassword" placeholder={t('new_password')} value={newPassword}
                       onChange={(e) => setNewPassword(e.target.value)} required/>
                <Input type="password" name="repeatPassword" placeholder={t('repeat_password')} value={repeatPassword}
                       onChange={(e) => setRepeatPassword(e.target.value)} required/>
                {error && <p style={{color: 'red'}}>{error}</p>}
                {success && <p style={{color: 'green'}}>{success}</p>}
                <button type="submit" className="btn btn-2 btn-align-right">{t('submit')}</button>
            </Form>
        </Wrapper>
    );
}

export default ChangePassword;