import React from "react";
import {OptionsContainer, Wrapper} from "./SignOptions.styles";
import GoogleIcon from "../../assets/Google.svg";
import FacebookIcon from "../../assets/Facebook.svg";
import AppleIcon from "../../assets/Apple.svg";
import Mail from "../../assets/mail.svg";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const SignOptions = ({signIn}) => {
    const {t} = useTranslation('common');
    const navigate = useNavigate();

    return (
        <Wrapper>
            {signIn ?
                <p>{t('sign_in_with')}</p>
                :
                <p>{t('sign_up_with')}</p>
            }
            <OptionsContainer>
                <a onClick={() => navigate('/login')}>
                    <img src={Mail} alt={t('log_in_with_mail')}/>
                </a>
                <img src={GoogleIcon} alt={t('log_in_with_google')}/>
                <img src={FacebookIcon} alt={t('log_in_with_facebook')}/>
                <img src={AppleIcon} alt={t('log_in_with_apple')}/>
            </OptionsContainer>
        </Wrapper>
    )
};

export default SignOptions;