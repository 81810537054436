import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

// Components
import {
    ButtonsContainer,
    LeftContainer,
    RightContainer,
    SideWrapper
} from '../components/SideWrapper/SideWrapper.styles';
import LogoAndText from '../components/LogoAndText';

const JoinUsPage = () => {
    const navigate = useNavigate();
    const {t} = useTranslation('common');

    return (
        <SideWrapper>
            <LeftContainer>
                <LogoAndText/>
            </LeftContainer>
            <RightContainer>
                <h2>{t('enter_email')}</h2>
                <ButtonsContainer>
                    <button onClick={() => navigate('/sign-up')} className='btn btn-4'>{t('sign_up')}</button>
                    <button onClick={() => navigate('/login')} className='btn btn-4'>{t('login')}</button>
                </ButtonsContainer>
            </RightContainer>
        </SideWrapper>
    );

}

export default JoinUsPage;
