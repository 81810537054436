import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {Container, Patient, PatientsContainer, PatientsContainerHeader, Wrapper} from "./DashboardBottom.styles";
import {getAllPatients} from '../../utils/api-lists/patient.api';
import Photo from "../../assets/photo.svg";
import {UserContext} from "../../context/UserContext";
import {FaChevronRight} from "react-icons/fa";
import {useTranslation} from "react-i18next";

const DashboardBottom = () => {
    const navigate = useNavigate();
    const {user} = useContext(UserContext);
    const [patients, setPatients] = useState([]);
    const [error, setError] = useState(null);
    const {t} = useTranslation('common');

    const fetchPatients = async () => {
        try {
            const data = await getAllPatients(user.id);
            setPatients(data);
        } catch (err) {
            setError(err);
        }
    };


    useEffect(() => {
        if (!user?.id) return;
        fetchPatients();
    }, [user?.id]);

    return (
        <Wrapper>
            <Container style={{width: '100%'}}>
                <PatientsContainer>
                    <PatientsContainerHeader>
                        <h5>{t('patient_list')}</h5>
                        <div>
                            <FaChevronRight onClick={() => navigate('/patient-list')} height={32}/>
                        </div>
                    </PatientsContainerHeader>
                    {error && <p style={{color: 'red'}}>{t('error_message')}</p>}
                    {patients.slice().reverse().slice(0, 4).map((patient) => (
                        <Patient key={patient.id} onClick={() => navigate(`/patient-cabinet/${patient.id}`)}
                                 className="clickable">
                            <div>
                                <img src={Photo} alt={t('patient')}/>
                                <p>{patient.first_name} {patient.last_name}</p>
                            </div>
                            <p id="patient-email">{patient.email}</p>
                        </Patient>
                    ))}
                </PatientsContainer>
            </Container>
        </Wrapper>
    )
};

export default DashboardBottom;
