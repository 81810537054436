import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {PatientContainer, PatientListContainer, PatientPhoto, Wrapper} from "./PatientList.styles";
import {getAllPatients} from '../../utils/api-lists/patient.api';
import Photo from "../../assets/photo.svg";
import {UserContext} from "../../context/UserContext";
import {DivRow} from "../../widgets/GeneralBody";
import {FaPlus} from "react-icons/fa";
import {useTranslation} from "react-i18next";

const PatientList = () => {
    const navigate = useNavigate();

    const [patients, setPatients] = useState([]);
    const [error, setError] = useState(null);
    const {user} = useContext(UserContext);
    const {t} = useTranslation('common');

    useEffect(() => {
        if (!user?.id) return;

        const fetchPatients = async () => {
            try {
                const data = await getAllPatients(user.id);
                setPatients(data);
            } catch (err) {
                setError(err);
            }
        };

        fetchPatients();
    }, [user?.id]);

    return (
        <Wrapper>
            <DivRow className="header-patient-list">
                <p>{t('patient_details')}</p>
                <FaPlus size={18} onClick={() => navigate('/new-patient')} className="clickable"/>
            </DivRow>
            <PatientListContainer>
                {error && <p style={{color: 'red'}}>{error}</p>}
                {patients.slice().reverse().map((patient) => (
                    <PatientContainer key={patient.id} onClick={() => navigate(`/patient-cabinet/${patient.id}`)}>
                        <p>{patient.first_name} {patient.last_name}</p>
                        <PatientPhoto src={Photo} alt="Patient"/>
                    </PatientContainer>
                ))}
            </PatientListContainer>
            {/*<button onClick={() => navigate('/new-patient')} type="submit" className="btn btn-2 btn-align-right">Add New*/}
            {/*    Patient*/}
            {/*</button>*/}
        </Wrapper>
    )
};

export default PatientList;
