import {
    Form,
    LeftContainer,
    RightContainer,
    SideWrapper,
    TextContainer
} from "../components/SideWrapper/SideWrapper.styles";
import LogoAndText from "../components/LogoAndText";
import SignOptions from "../components/SignOptions";
import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../context/UserContext";
import {forgetPassword} from "../utils/api-lists/login.api";
import {useTranslation} from "react-i18next";

const ForgetPasswordPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const {loginUser} = useContext(UserContext);
    const {t} = useTranslation('common');

    const handleSubmit = async (e) => {
        console.log('Submit');
        e.preventDefault();
        try {
            const user = await forgetPassword(email);
            if (user) {
                navigate('/success');
            }
        } catch (err) {
            setError(err);
        }
    };

    return (
        <SideWrapper>
            <LeftContainer>
                <LogoAndText/>
            </LeftContainer>
            <RightContainer>
                <h2>{t('forget_password')}</h2>
                <TextContainer>
                    <h3>{t('enter_email')}</h3>
                </TextContainer>
                <Form onSubmit={handleSubmit} style={{marginTop: "121px", maxWidth: "400px"}}>
                    <label>{t('email')}</label>
                    <input placeholder={t('email')} name='email' type='email'
                           onChange={(e) => setEmail(e.target.value)}/>
                    {error && <p style={{color: 'red'}}>{t('error_message')}</p>}
                    <button type="submit" className="btn btn-4">{t('next')}</button>
                </Form>
                <SignOptions signIn={true}/>
                <p>{t('dont_have_account')} <span onClick={() => navigate('/sign-up')}>{t('sign_up')}</span></p>
            </RightContainer>
        </SideWrapper>
    );
}

export default ForgetPasswordPage;