import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useRole} from '../context/RoleContext';

// Components
import {
    ButtonsContainer,
    LeftContainer,
    RightContainer,
    SideWrapper
} from '../components/SideWrapper/SideWrapper.styles';
import LogoAndText from '../components/LogoAndText';
import {useTranslation} from "react-i18next";

const Signup1Page = () => {
    const navigate = useNavigate();
    const {t} = useTranslation('common');
    const {setRoleCheck} = useRole();

    const handleRedirect = (role) => {
        const encodedRole = encodeURIComponent(role.toUpperCase());
        setRoleCheck(role.toUpperCase());
        navigate(`/sign-up-next/${encodedRole}`);
    };

    return (
        <SideWrapper>
            <LeftContainer>
                <LogoAndText/>
            </LeftContainer>
            <RightContainer>
                <h2>{t('profession')}</h2>
                <ButtonsContainer>
                    <button onClick={() => handleRedirect('Cosmetologist')}
                            className='btn btn-4'>{t('cosmetologist')}</button>
                    <button onClick={() => handleRedirect('Dermatologist')}
                            className='btn btn-4'>{t('dermatologist')}</button>
                </ButtonsContainer>
                <p>{t('have_an_account')} <span onClick={() => navigate('/login')}>{t('sign_in')}</span></p>
            </RightContainer>
        </SideWrapper>
    );

}

export default Signup1Page;
