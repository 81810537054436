import React, {useEffect, useState} from "react";
import {
    Button,
    DateText,
    Form,
    Input,
    Popup,
    Section,
    TextArea,
    VisitItem,
    VisitsContainer,
    Wrapper
} from "./PatientProfile.styles";
import {useParams} from 'react-router-dom';
import {getPatient} from '../../utils/api-lists/patient.api';
import {createVisit, getRecentVisits} from '../../utils/api-lists/visit.api';

import ProfileImage from "../../assets/profile-img.svg";
import {StyledModal} from "../../pages/PatientCabinet/styles";
import CloseModal from "../CloseModal";
import {useTranslation} from "react-i18next";

const PatientProfile = () => {
    const {patientId} = useParams();
    const [patient, setPatient] = useState(null);
    const [visits, setVisits] = useState([]);
    const [error, setError] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedVisit, setSelectedVisit] = useState(null);
    const [note, setNote] = useState('');
    const [date, setDate] = useState(new Date().toISOString().substr(0, 10));
    const {t} = useTranslation('common');

    useEffect(() => {
        const fetchPatient = async () => {
            try {
                const data = await getPatient(patientId);
                setPatient(data);
            } catch (err) {
                setError(err.message);
            }
        };

        const fetchVisits = async () => {
            try {
                const data = await getRecentVisits(patientId);
                setVisits(data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchPatient();
        fetchVisits();
    }, [patientId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await createVisit(patientId, note, date);
            const updatedVisits = await getRecentVisits(patientId);
            setVisits(updatedVisits);
            setShowPopup(false);
            setSelectedVisit(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDateClick = (visit) => {
        setSelectedVisit(visit);
        setShowPopup(true);
    };

    if (error) {
        return <p style={{color: 'red'}}>{t('error_message')}</p>;
    }

    if (!patient) {
        return <p>{t('loading')}</p>;
    }

    return (
        <Wrapper>
            <Section>
                <h3>{t('patient_details')}</h3>
                <p>{patient.first_name} {patient.last_name}</p>
                <p>{patient.email}</p>
                <p>{patient.phone}</p>
            </Section>
            <Section>
                <h3>{t('last_visits')}</h3>
                <VisitsContainer>
                    {visits.map((visit) => (
                        <VisitItem key={visit.id} onClick={() => handleDateClick(visit)}>
                            <DateText>{new Date(visit.date).toLocaleDateString()}</DateText>
                        </VisitItem>
                    ))}
                    <VisitItem isButton>
                        <button className="btn btn-2 btn-align-right" onClick={() => setShowPopup(true)}>
                            {t('record_visits')}
                        </button>
                    </VisitItem>
                </VisitsContainer>
            </Section>
            <Section className="hide-on-mobile">
                <img src={ProfileImage} alt={t('profile_image')}/>
            </Section>

            {/*{showPopup && (*/}
            {/*    <Popup>*/}
            {/*        <CloseButton onClick={() => {*/}
            {/*            setShowPopup(false);*/}
            {/*            setSelectedVisit(null);*/}
            {/*        }}>*/}
            {/*            <CloseIcon/>*/}
            {/*        </CloseButton>*/}
            {/*        {selectedVisit ? (*/}
            {/*            <div>*/}
            {/*                <h3>Visit Details</h3>*/}
            {/*                <p>Date: {new Date(selectedVisit.date).toLocaleDateString()}</p>*/}
            {/*                <p>Note: {selectedVisit.note}</p>*/}
            {/*            </div>*/}
            {/*        ) : (*/}
            {/*            <Form onSubmit={handleSubmit}>*/}
            {/*                <Input*/}
            {/*                    type="date"*/}
            {/*                    value={date}*/}
            {/*                    onChange={(e) => setDate(e.target.value)}*/}
            {/*                />*/}
            {/*                <TextArea*/}
            {/*                    value={note}*/}
            {/*                    onChange={(e) => setNote(e.target.value)}*/}
            {/*                    placeholder="Enter note (at least 10 characters)"*/}
            {/*                />*/}
            {/*                <Button type="submit">Submit</Button>*/}
            {/*            </Form>*/}
            {/*        )}*/}
            {/*    </Popup>*/}
            {/*)}*/}
            <StyledModal
                isOpen={showPopup}
                onBackgroundClick={() => {
                    setShowPopup(false);
                    setSelectedVisit(null);
                }}
                onEscapeKeydown={() => {
                    setShowPopup(false);
                    setSelectedVisit(null);
                }}>
                <Popup>
                    {selectedVisit ? (
                        <div>
                            <h3>{t('visit_details')}</h3>
                            <p>{t('date')}: {new Date(selectedVisit.date).toLocaleDateString()}</p>
                            <p>{t('note')}: {selectedVisit.note}</p>
                        </div>
                    ) : (
                        <Form onSubmit={handleSubmit}>
                            <Input
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                            />
                            <TextArea
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                placeholder={t('enter_note')}
                            />
                            <Button type="submit">{t('submit')}</Button>
                        </Form>
                    )}
                </Popup>
                <CloseModal onClick={() => {
                    setShowPopup(false);
                    setSelectedVisit(null);
                }}/>
            </StyledModal>
        </Wrapper>
    )
};

export default PatientProfile;
