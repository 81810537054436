import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: rgba(88, 80, 109, 0.30);
    padding: 20px;
    width: 180px;
    height: 180px;
    cursor: pointer;

    p {
        text-align: center;
    }
`;

export const ImgTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 40px;
        height: 40px;
        margin-bottom: 15px;
    }
`;