import styled from "styled-components";

export const CanvasWrapper = styled.div`
    position: relative;
    max-width: 100%;
    margin: 0 auto;

    .mirrorCanvas {
        canvas {
            transform: scaleX(-1);
        }
    }
`;
