import React from "react";
import {Select} from "./Select.style";

const SelectField = ({children, onClick, disabled, defaultValue, onChange}) => {

    return (
        <Select onClick={onClick} disabled={disabled} defaultValue={defaultValue} onChange={onChange}>
            {children}
        </Select>
    )
};

export default SelectField;
