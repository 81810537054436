import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {UserProvider} from './context/UserContext';
import {RoleProvider} from './context/RoleContext';
import {ModalProvider} from 'styled-react-modal'
import i18next from "i18next";
import {I18nextProvider} from "react-i18next";

import common_it from "./translations/it/common.json";
import common_en from "./translations/en/common.json";

const currentLanguage = localStorage.getItem('language') || 'en';


i18next.init({
    interpolation: {escapeValue: false},  // React already does escaping
    lng: currentLanguage,                              // language to use
    resources: {
        en: {
            common: common_en,
        },
        it: {
            common: common_it,
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <UserProvider>
        <I18nextProvider i18n={i18next}>
            <ModalProvider>
                <RoleProvider>
                    <App/>
                </RoleProvider>
            </ModalProvider>
        </I18nextProvider>
    </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
