import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

// Components
import {DashboardWrapper} from '../components/DashboardWrapper/DashboardWrapper.styles';
import Input from '../components/Input';
import TextArea from "../components/textarea";
import {Form} from "../components/NewPatient/NewPatient.styles";

const PatientListPage = () => {
    const {t} = useTranslation('common');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // const response = await createPatient(user.id, firstName, lastName, email, phone);
            // console.log(response);
            setSuccess(t('email_sent_successfully'));
            setError(null);
            setName('');
            setEmail('');
        } catch (err) {
            setError(err);
            setSuccess(null);
        }
    };

    return (
        <DashboardWrapper>
            <div style={
                {
                    textAlign: 'center',
                    maxWidth: '60%',
                    margin: 'auto',
                }
            }>
                <h1>{t('title')}</h1>
                <p>{t('description')}</p>

                <h1 className='padding-top-24'>{t('contact')}</h1>
                <Form onSubmit={handleSubmit}>
                    <Input type="text" name="name" placeholder={t('name')} value={name}
                           onChange={(e) => setName(e.target.value)} required/>
                    <Input type="email" name="email" placeholder={t('email')} value={email}
                           onChange={(e) => setEmail(e.target.value)} required/>
                    <TextArea name="message" placeholder={t('message')} value={message}
                              onChange={(e) => setMessage(e.target.value)} required/>
                    {error && <p style={{color: 'red'}}>{error}</p>}
                    {success && <p style={{color: 'green'}}>{success}</p>}
                    <button type="submit" className="btn btn-2 btn-align-right">{t('submit')}</button>
                </Form>
            </div>
        </DashboardWrapper>
    );

}

export default PatientListPage;
